import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { useIntl } from '@utils/localize';
import isAfter from 'date-fns/isAfter';
import get from 'lodash/get';

import blockFactory from '@utils/blockFactory';
import getLocalizedData from '@utils/localize';
import { useDateString } from '@utils/string';
import { TranslationContext } from '@utils/useTranslations';
import Container from '@components/Container';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import Navigation from '@components/Navigation';
import SEO from '@components/SEO';
import Space from '@components/Space';
import UpcomingEvents from '@components/UpcomingEvents';

export const query = graphql`
  query EventQuery($id: String!) {
    event: sanityEvent(id: { eq: $id }) {
      id
      published_vi
      published_zh_Hans
      allDay
      category {
        title {
          en
          vi
          zh_Hans
          _type
        }
      }
      endTime
      eventbriteLink
      excerpt {
        en
        vi
        zh_Hans
        _type
      }
      locationLabel {
        en
        vi
        zh_Hans
        _type
      }
      mapLink
      slug {
        current
      }
      startTime
      tags
      showChat
      title {
        en
        vi
        zh_Hans
        _type
      }
      _rawContent(resolveReferences: { maxDepth: 12 })
      isExcludedFromSearch
    }
    upcomingEvents: allSanityEvent(sort: { fields: startTime, order: ASC }) {
      edges {
        node {
          id
          allDay
          category {
            title {
              en
              vi
              zh_Hans
              _type
            }
          }
          endTime
          excerpt {
            en
            vi
            zh_Hans
            _type
          }
          slug {
            current
          }
          startTime
          tags
          title {
            en
            vi
            zh_Hans
            _type
          }
        }
      }
    }
  }
`;

const EventTemplate = (props) => {
  const { data: rawData } = props;
  const intl = useIntl();
  const data = getLocalizedData(
    rawData,
    get(rawData, `event.published_${intl.locale}`) ? intl.locale : 'en',
  );
  const getDateString = useDateString();

  const translationContext = {
    vi: get(rawData, 'event.published_vi'),
    zh_Hans: get(rawData, 'event.published_zh_Hans'),
  };

  const event = data && data.event;

  const upcomingEvents =
    data &&
    data.upcomingEvents.edges
      .map(({ node }) => ({
        ...node,
        title: node.title,
        startTime: new Date(node.startTime),
        endTime: new Date(node.endTime),
      }))
      .filter((e) => isAfter(e.startTime, new Date()) && e.id !== get(event, 'id'))
      .slice(0, 3);

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={props.banner} navItems={props.navItems} />
        <main>
          <SEO
            title={event.title}
            noIndex={event.isExcludedFromSearch}
          />
          <Hero
            breadcrumb={
              event.allDay
                ? ''
                : {
                    self: getDateString(
                      new Date(event.startTime),
                      new Date(event.endTime),
                    ),
                  }
            }
            heading={event.title}
            headingAlignment="center"
            theme="blue"
          />
          {(event._rawContent || []).map((block, idx) => (
            <Fragment key={idx}>
              {blockFactory(block, event, intl.locale)}
            </Fragment>
          ))}
          <Container centered columns={[12, 5]}>
            <Space size="small" />
            {upcomingEvents.length > 0 && (
              <UpcomingEvents events={upcomingEvents} />
            )}
          </Container>
        </main>
        {/*<Space sizes={['xsmall', 'small']} />*/}
        <Footer showChat={event.showChat} />
      </TranslationContext.Provider>
    </>
  );
};

export default EventTemplate;
